import {
	Button,
	Carousel,
	Col,
	Divider,
	FloatButton,
	Layout,
	Row,
	Typography,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookF,
	faInstagram,
	faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { GET } from "../../../providers/useAxiosQuery";
import { useEffect, useState } from "react";
import { faArrowUp } from "@fortawesome/pro-regular-svg-icons";

export default function PageLanding() {
	const [activeButton, setActiveButton] = useState("home");
	const [isVisible, setIsVisible] = useState(false);

	const scrollToSection = (id) => {
		const yOffset = -100;
		const element = document.getElementById(id);
		if (element) {
			let top = element.getBoundingClientRect().top;
			const y = top + window.scrollY + yOffset;
			window.scrollTo({
				top: y,
				behavior: "smooth",
			});
		}
	};

	const { data: dataSource } = GET(
		`api/system_link_list`,
		"system_link_list",
		(res) => {
			// console.log("mobile_student_form res", res);
		},
		false
	);

	// console.log("dataSource: ", dataSource);

	useEffect(() => {
		const toggleVisibility = () => {
			if (window.pageYOffset > 200) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener("scroll", toggleVisibility);

		return () => window.removeEventListener("scroll", toggleVisibility);
	}, []);

	return (
		<Layout.Content>
			<Layout.Header>
				<div className="left-menu">
					<div className="logo-wrapper">
						<img src="/assets/images/logo_sidemenu.png" alt="FSUU LOGO" />
					</div>
				</div>

				<div className="right-menu">
					<Button
						type="link"
						className={`btn-menu ${activeButton === "home" ? "active" : ""}`}
						onClick={() => {
							setActiveButton("home");
							scrollToSection("carousel-wrapper");
						}}
					>
						HOME
					</Button>

					<Button
						type="link"
						className={`btn-menu ${
							activeButton === "projects" ? "active" : ""
						}`}
						onClick={() => {
							setActiveButton("projects");
							scrollToSection("projects-wrapper");
						}}
					>
						PROJECTS
					</Button>

					<Button
						type="link"
						className={`btn-menu ${activeButton === "about" ? "active" : ""}`}
						onClick={() => {
							setActiveButton("about");
							scrollToSection("about-wrapper");
						}}
					>
						ABOUT
					</Button>

					<Button
						type="link"
						className={`btn-menu ${activeButton === "contact" ? "active" : ""}`}
						onClick={() => {
							setActiveButton("contact");
							scrollToSection("contact-wrapper");
						}}
					>
						CONTACT
					</Button>
				</div>
			</Layout.Header>

			<div id="carousel-wrapper" className="section carousel-wrapper">
				<Carousel arrows autoplay>
					<div className="fsuu-content">
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className="leftside">
							<Typography.Title level={3}>
								Hello There. Welcome
							</Typography.Title>
							<Typography.Title className="mt-5">
								Father Saturnino Urios University
							</Typography.Title>
							<br />
							<p className="description">
								{/* Introduction for Father Saturnino Urios University */}
								Lorem ipsum dolor sit amet, consectetur adipisicing elit.
								Tempora corrupti unde, doloribus aliquid delectus sit odit
								quaerat eos vel voluptas? Lorem ipsum dolor sit amet consectetur
								adipisicing elit. Debitis, nulla quas iure eligendi corrupti
								optio praesentium voluptatem aperiam ipsam quae.
							</p>

							<Button type="primary" className="btn-main-primary mb-10">
								Read More
							</Button>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className="rightside">
							<div className="fsuu-logo">
								<img
									src="/assets/images/fsuu_logo.png"
									alt="FSUU-LOGO"
									width={250}
									height={250}
								/>
							</div>
						</Col>
					</div>

					<div className="fsuu-content">
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className="leftside">
							<Typography.Title level={3}>
								Hello There. Welcome
							</Typography.Title>
							<Typography.Title className="mt-5">
								Data Science and Analytics Center
							</Typography.Title>
							<br />
							<p className="description">
								{/* Introduction for Father Saturnino Urios University */}
								Lorem ipsum dolor sit amet, consectetur adipisicing elit.
								Tempora corrupti unde, doloribus aliquid delectus sit odit
								quaerat eos vel voluptas? Lorem ipsum dolor sit amet consectetur
								adipisicing elit. Debitis, nulla quas iure eligendi corrupti
								optio praesentium voluptatem aperiam ipsam quae.
							</p>

							<Button type="primary" className="btn-main-primary mb-10">
								Read More
							</Button>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className="rightside">
							<div className="fsuu-logo">
								<img
									src="/assets/images/dsac_logov2.png"
									alt="DSAC-LOGO"
									width={250}
									height={250}
								/>
							</div>
						</Col>
					</div>

					<div className="fsuu-content">
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className="leftside">
							<Typography.Title level={3}>
								Hello There. Welcome
							</Typography.Title>
							<Typography.Title className="mt-5">
								Technology Business Incubator
							</Typography.Title>
							<br />
							<p className="description">
								{/* Introduction for Father Saturnino Urios University */}
								Lorem ipsum dolor sit amet, consectetur adipisicing elit.
								Tempora corrupti unde, doloribus aliquid delectus sit odit
								quaerat eos vel voluptas? Lorem ipsum dolor sit amet consectetur
								adipisicing elit. Debitis, nulla quas iure eligendi corrupti
								optio praesentium voluptatem aperiam ipsam quae.
							</p>

							<Button type="primary" className="btn-main-primary mb-10">
								Read More
							</Button>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} className="rightside">
							<div className="fsuu-logo">
								<img
									src="/assets/images/moesis_logo.png"
									alt="DSAC-LOGO"
									width={250}
									height={290}
								/>
							</div>
						</Col>
					</div>
				</Carousel>
			</div>

			<div id="projects-wrapper" className="section projects-wrapper">
				{/* <div className="">WORKS</div> */}
				<Divider>
					<Typography.Title className="project-banner">
						PROJECTS
					</Typography.Title>
				</Divider>

				<div className="projects-content">
					<Row gutter={[20, 0]}>
						{dataSource && dataSource.data ? (
							dataSource.data.map((item) => {
								return (
									<Col xs={24} sm={24} md={24} lg={12} xl={12}>
										<div className="project-container">
											<div className="project-image">
												<img
													src="/assets/images/dsac_bg.jpg"
													// scr={
													// 	item.attachments && item.attachments.length > 0
													// 		? item.attachments[0].file_path
													// 		: "/assets/images/dsac_bg.jpg"
													// }
													alt="Project Logo"
												/>
											</div>

											<Button
												type="link"
												className="icon-wrapper"
												onClick={() => {
													window.open(item.url, "_blank");
												}}
											>
												<img
													src="/assets/images/icons/link-icon.png"
													alt="Icon Logo"
												/>
											</Button>

											<div className="project-detail">
												<Typography.Title level={2}>
													{item.name}
												</Typography.Title>
												<Typography.Title level={5}>
													{item.description}
												</Typography.Title>
											</div>
										</div>
									</Col>
								);
							})
						) : (
							<Col xs={24} sm={24} md={24} lg={12} xl={12}>
								<Typography.Title level={2}>
									No Projects Available
								</Typography.Title>
							</Col>
						)}
					</Row>
				</div>
			</div>

			<div id="about-wrapper" className="section about-wrapper">
				<Divider>
					<Typography.Title className="project-banner">ABOUT</Typography.Title>
				</Divider>

				<div className="about-content">
					<Row gutter={[20, 0]}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<Typography.Title className="mb-50">
								Welcome to our website! Here, you can discover the latest
								projects that developers are working on. We've made it simple to
								explore by including links that take you directly to each
								project. So, feel free to come in and check out what's
								available!
							</Typography.Title>
						</Col>
					</Row>
				</div>
			</div>

			<div id="contact-wrapper" className="section contact-wrapper">
				<Divider>
					{/* <Typography.Title className="project-banner">
						CONTACT
					</Typography.Title> */}
					<img src="/assets/images/logo_sidemenu.png" alt="FSUU LOGO" />
				</Divider>

				<div className="social-media">
					<Row gutter={[20, 0]}>
						<Col xs={24} sm={24} md={24} lg={24} xl={24}>
							<a
								href="https://www.facebook.com/fsuudsac"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Button type="link" className="social-links">
									<FontAwesomeIcon icon={faFacebookF} />
								</Button>
							</a>

							<a
								href="https://www.instagram.com/explore/locations/100677159385308/fsuu-data-science-analytics-center-dsac/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Button type="link" className="social-links">
									<FontAwesomeIcon icon={faInstagram} />
								</Button>
							</a>

							<Button type="link" className="social-links">
								<FontAwesomeIcon icon={faTwitter} />
							</Button>
						</Col>
					</Row>
				</div>
			</div>

			<FloatButton
				icon={<FontAwesomeIcon icon={faArrowUp} />}
				onClick={() => scrollToSection("carousel-wrapper")}
				className={isVisible ? "float-btn-show" : "float-btn-hide"}
				type="default"
			/>

			<div id="footer-wrapper" className="section footer-wrapper">
				<Row gutter={[20, 0]}>
					<Col xs={24} sm={24} md={24} lg={6} xl={6}>
						<Typography.Title level={4}>Terms & Conditions</Typography.Title>
					</Col>

					<Col xs={24} sm={24} md={24} lg={6} xl={6} textAlign="center">
						Copyright © 2024 FSUU-DSAC All Rights Reserved.
					</Col>
				</Row>
			</div>
		</Layout.Content>
	);
}
