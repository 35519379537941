import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import "../assets/scss/app.scss";
import RouteList from "./RouteList";

const queryClient = new QueryClient();

export default function Routers() {
	useEffect(() => {
		const clearCacheData = () => {
			if ("caches" in window) {
				caches.keys().then((names) => {
					names.forEach((name) => {
						caches.delete(name);
					});
				});
			}
			console.log("Cache cleared");
		};

		const checkForUpdates = () => {
			const currentVersion = "1.0.2"; // Replace with your current version
			const storedVersion = localStorage.getItem("appVersion");

			if (storedVersion !== currentVersion) {
				clearCacheData();
				localStorage.setItem("appVersion", currentVersion);
			}
		};

		checkForUpdates();

		return () => {};
	}, []);

	return (
		<QueryClientProvider client={queryClient}>
			<Router>
				<RouteList />
			</Router>
		</QueryClientProvider>
	);
}
